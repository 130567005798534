import React from 'react';
import './About.css';
import about_img from '../assests/values_image_From-The-Rising-Protest-Series-Vikram-Bhise-Anant.jpeg';

function About() {
  return (
    <div className="about-container">
      <div className="about-image-container">
        <img src={about_img} alt="Art Gallery" className="about-image" />
      </div>
      <div className="about-text-container">
        <p className="about-para">WE ARE ABOUT</p>
        <p className="about-description">
          Its people that make politics and yet so much of our politics today in India is far removed from us than ever.
          Only we can change it
        </p>
        <p className="about-description">
          At Satyagraha, we are about moving people up politics by connecting talent to grassroots movements and activists.
          If you are ready to help the next progressive, young, grassroots person make it in politics, sign up today!
        </p>
        <p className="about-description">
        <a href="https://work.satyagraha.dev/about-us">Read More</a>
        </p>
      </div>
    </div>
  );
}

export default About;
