// Values.jsx

import React, { useState } from 'react';
import flag from '../assests/flag.jpeg';
import './Values.css';

function Values() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const valuesData = [
    {
      title: 'CONSTITUTIONALISM',
      previewText: 'This is a preview of the blog post about Constitutionalism.',
      link: '/link-to-post-1',
    },
    {
      title: 'RULE OF LAW',
      previewText: 'This is a preview of the blog post about Rule of Law.',
      link: '/link-to-post-2',
    },
    {
      title: 'FEDERALISM',
      previewText: 'This is a preview of the blog post about Federalism.',
      link: '/link-to-post-3',
    },
    {
      title: 'CIVIL LIBERTIES',
      previewText: 'This is a preview of the blog post about Civil Liberties.',
      link: '/link-to-post-4',
    },
    {
      title: 'INCLUSIVE GROWTH',
      previewText: 'This is a preview of the blog post about Inclusive Growth.',
      link: '/link-to-post-5',
    },
  ];

  return (
    <div className="values-container">
      <div className="left">
        {valuesData.map((value, index) => (
          <div
            key={index}
            className="value-item"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <a href={value.link} className="value-button">
              <span>{value.title}</span>
              <div className={`preview-text ${activeIndex === index ? 'active' : ''}`}>
                <p>{value.previewText}</p>
                <a href={value.link}>Read more</a>
              </div>
            </a>
          </div>
        ))}
      </div>
      <div className="right">
        <div className="image-container">
          <img src={flag} alt="flag" />
        </div>
      </div>
    </div>
  );
}

export default Values;
