import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LandingPage from './LandingPage';
import Website from './Website';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route
// } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/values',
    element: <Website />

  },
  {
    path: '/',
    element: <LandingPage />

  },


]);


export default function App() {
  return <RouterProvider router={router} />;
}
 

// export default function App() {
//   return (
//     <Switch>
//       <Route path='/' exact>
//         <landingPage />
//       </Route>
//     </Switch>
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<landingPage/>}>

    //     </Route>
    //   </Routes>
    // </BrowserRouter>
//   );
// }

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);