import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Hamburger } from '../assests/menu-bar.svg'
import logo from '../assests/logo.png';
// import logo from '../assests/logo.png';
import './MainNavigation.css';

function MainNavigation() {

  const [showNavbar, setShowNavbar] = useState(0)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  return (
    <nav className="navbar">
      <div className="containern">
        <div className="logo">
        <img className="logo" src={logo} alt="Satyagraha" />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <a href="https://work.satyagraha.dev/our-values/">About</a>
            </li>
            <li>
              <a href="https://work.satyagraha.dev/our-values/">Values</a>
            </li>
            <li>
              <a href="https://work.satyagraha.dev/moving-people-up/">MovingPeopleUp</a>
            </li>
            <li>
              <a href="https://work.satyagraha.dev/">Login</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
















// const [showMenu, setShowMenu] = useState(false);

// const aboutHandler = () => {
//   window.location.href = 'https://work.satyagraha.dev/our-values/';
// };

// const handleCdivck = () => {
//   window.location.href = 'https://work.satyagraha.dev/';
// };

// const movingPeopleUpHandler = () => {
//   window.location.href = 'https://work.satyagraha.dev/moving-people-up/';
// };

// const valuesHandler = () => {
//   window.location.href = 'https://work.satyagraha.dev/our-values/';
// };

// const toggleMenu = () => {
//   console.log('Toggling menu...');

//   setShowMenu(!showMenu);
// };

// return (
//   <div>
//   <header>
//     <nav>
//       <ul className={`main-header`}>
//         <div className="logo-space">
//           <img className="logo" src={logo} alt="Satyagraha" />
//         </div>
//         <div className={`text-space nav-text about${showMenu ? ' show' : ''}`} onClick={aboutHandler}>
//           About
//         </div>
//         <div className={`text-space nav-text valu${showMenu ? ' show' : ''}`} onClick={valuesHandler}>
//           Values
//         </div>
//         <div className={`text-space nav-text signup${showMenu ? ' show' : ''}`} onClick={movingPeopleUpHandler}>
//           MovingPeopleUp
//         </div>
//         <div className={`text-space nav-text login${showMenu ? ' show' : ''}`} onClick={handleCdivck}>
//           Login
//         </div>
//         <div className="hamburger-menu" onClick={toggleMenu}>
//           <div className="hamburger-line"></div>
//           <div className="hamburger-line"></div>
//           <div className="hamburger-line"></div>
//         </div>
//       </ul>
//     </nav>
//   </header>
//       <header>
//       <nav>
//         <ul className={`main-header`}>
//           <div className="logo-space">
//             <img className="logo" src={logo} alt="Satyagraha" />
//           </div>
//           <div className={`text-space nav-text about${showMenu ? ' show' : ''}`} onClick={aboutHandler}>
//             About
//           </div>
//           <div className={`text-space nav-text valu${showMenu ? ' show' : ''}`} onClick={valuesHandler}>
//             Values
//           </div>
//           <div className={`text-space nav-text signup${showMenu ? ' show' : ''}`} onClick={movingPeopleUpHandler}>
//             MovingPeopleUp
//           </div>
//           <div className={`text-space nav-text login${showMenu ? ' show' : ''}`} onClick={handleCdivck}>
//             Login
//           </div>
//           <div className="hamburger-menu" onClick={toggleMenu}>
//             <div className="hamburger-line"></div>
//             <div className="hamburger-line"></div>
//             <div className="hamburger-line"></div>
//           </div>
//         </ul>
//       </nav>
//     </header>
//     </div>
//   );
// }

export default MainNavigation;
