import React from 'react';

import MainNavigation from './components/MainNavigation';
import SignupForm from './components/SignupForm';
import About from './components/About'
import Footer from './components/Footer';
import Values from './components/Values'
import './landingPage.css';

export default function LandingPage() {
  console.log("him")
  return (
    <div>
      <MainNavigation />
      <SignupForm />
      <About />
      {/* <ReviewComponent/> */}
      <Values />
      <Footer />
    </div>
  );
}
