import React, { useState } from 'react';
import './SignupForm.css';
import signup_background from '../assests/signup_background.png';


const headers = new Headers({
  'X-Civi-Auth': 'Bearer 9HB0rjnGk01EFUk1ZSEQNirM',
  'X-Requested-With': 'XMLHttpRequest',
  'X-Civi-Key': '8COuH8blFGzm862DxayYHGmdgxSCQIZNJCLRl4JbbA',
  // 'Authorization': 'Bearer BrMiIMoniKmr8FkEhw2Y0R38',
  // 'Cookie': 'PHPSESSID=53dct8kp72v7lsmldog7ukse3a',
  // 'Access-Control-Allow-Origin': '*',
});


function SignupForm() {

  const [onChangeName, setOnChangeName] = useState('')
  const [onChangeEmail, setOnChangeEmail] = useState('')
  const [onChangePhone, setOnChangePhone] = useState('')
  const [onChangePincode, setOnChangePincode] = useState('')
  const [isWhatsAppChecked, setIsWhatsAppChecked] = useState(false);

  const handleWhatsAppUpdateChange = () => {
    setIsWhatsAppChecked(!isWhatsAppChecked);
  };

  const onSubmit = async (event) => {
    console.log(onChangeName)
    // const randomNum = Math.floor(Math.random() * (100000000000));
    // writeUserData(randomNum, onChangeName, onChangeEmail, onChangePhone, onChangePincode)

    async function signupUser() {
      try {

        //First Create User Request
        const createContactQueryParamObj = new URLSearchParams();
        const createContactParams = {
          values: {
            first_name: onChangeName,
            preferred_communication_method: isWhatsAppChecked ? [6] : [],
          }
        }
        createContactQueryParamObj.append('params', JSON.stringify(createContactParams));
        const url = `https://work.satyagraha.dev/home/ajax/api4/Contact/create?${createContactQueryParamObj}`
        const createContactRes = await fetch(url, {
          method: 'POST',
          headers: headers,
        });

        if (!createContactRes.ok) {
          alert(`Failed to create User`);
          return;
        }

        const createContactResData = await createContactRes.json();

        console.log('create user api response data', createContactResData);



        //First Create Emial Request
        const createEmailQueryParams = new URLSearchParams();
        const emailParams = {
          values: {
            contact_id: createContactResData.values[0].id,
            email: onChangeEmail,
            is_primary: true,
            'location_type_id:name': 'Main'
          }
        }
        createEmailQueryParams.append('params', JSON.stringify(emailParams));
        const creatEmailUrl = `https://work.satyagraha.dev/home/ajax/api4/Email/create?${createEmailQueryParams}`
        const createEmailRes = await fetch(creatEmailUrl, {
          method: 'POST',
          headers: headers,
        });

        if (!createEmailRes.ok) {
          alert(`Failed to create Email`);
          return;
        }

        const createEmailResData = await createEmailRes.json();

        console.log('create email api response data', createEmailResData);


        //Create Phone number Request
        const createPhoneNumberQpObj = new URLSearchParams();
        const createPhoneParams = {
          values: {
            contact_id: createContactResData.values[0].id,
            phone: '1234567890'
          }
        }
        createPhoneNumberQpObj.append('params', JSON.stringify(createPhoneParams));
        const createPhoneUrl = `https://work.satyagraha.dev/home/ajax/api4/Phone/create?${createPhoneNumberQpObj}`
        const createPhoneApiRes = await fetch(createPhoneUrl, {
          method: 'POST',
          headers: headers,
        });

        if (!createPhoneApiRes.ok) {
          alert(`Failed to create Phone`);
          return;
        }

        const createPhoneApiResData = await createPhoneApiRes.json();

        console.log('create phone api response data', createPhoneApiResData);


        //Create Address - pincode Request
        const createAddresQPObj = new URLSearchParams();
        const createAddresQPValues = {
          values: {
            contact_id: createContactResData.values[0].id,
            postal_code: onChangePincode
          }
        }
        createAddresQPObj.append('params', JSON.stringify(createAddresQPValues));
        const createAddresUrl = `https://work.satyagraha.dev/home/ajax/api4/Address/create?${createAddresQPObj}`
        const createAddressApiRes = await fetch(createAddresUrl, {
          method: 'POST',
          headers: headers,
        });

        if (!createAddressApiRes.ok) {
          alert(`Failed to create address`);
          return;
        }

        const createAddressApiResData = await createAddressApiRes.json();

        console.log('create address api response data', createAddressApiResData);

        alert('User registration success!');

        //Reset Form
        setOnChangeName('');
        setOnChangeEmail('');
        setOnChangePhone('');
        setOnChangePincode('');

      } catch (error) {
        alert('Something went wrong! Please try again after sometime.')
      }
    }
    signupUser();


  }
     

  return (
    <div >
      <div className="background-img" >
        <div className="signup-container" >
      <div className="signup-left" >
          <div className='signup-left_item'>#MovingPeopleUp In Politics Through Citizen Activism</div>
      </div>
      <div className="right">
        <div className="form_container">
        <h1 className='form_title'>READY TO HELP?</h1>
        <p className='form_description'>Satyagraha is a volunteer powered young and vibrant movement to transform electoral politics by moving poeple like us in power. If you have what it takes to mobilise people, build digital infrastructure for our movement, or help our grassroot champions with their campaigns, get in touch with us by signing up</p>
        <form>
          <div className="form_item">
            <label htmlFor="name"></label>
            <input className="form_input_field" type="text" id="name" name="name" placeholder='Name'
              onChange={event => setOnChangeName(event.target.value)}></input>
          </div>
          <div className="form_item">
            <label  htmlFor="email"></label>
            <input className="form_input_field" type="text" id="email" name="email" placeholder='Email'
              onChange={event => setOnChangeEmail(event.target.value)}></input>
          </div>
          <div className="form_item">
            <label  htmlFor="username"></label>
            <input className="form_input_field" type="text" id="phone" name="phone" placeholder='Phone'
              onChange={event => setOnChangePhone(event.target.value)}></input>
          </div>
          <div className="form_item">
            <label htmlFor="pincode"></label>
            <input className="form_input_field" type="pincode" id="pincode" name="pincode" placeholder='Pincode'
              onChange={event => setOnChangePincode(event.target.value)}></input>
          </div>
          <div className='form_input_para form_input_field' style={{ display: 'flex', alignItems: 'center' }}>
            {/* <p className="form_input_para">Sign me up for Whatsapp updates. No spams, only critical communications. Max once in a week</p> */}
            <input type="checkbox" name="whatsapp_update" value="whatsappUpdate" checked={isWhatsAppChecked}
              onChange={handleWhatsAppUpdateChange} />
            <label className="" htmlFor="whatsapp_update">Sign me up for Whatsapp updates. No spams, only critical communications. Max once in a week</label><br></br>
          </div>
          {/* <div>
          <button  onClick={onSubmit}>Sign-up</button>
        </div> */}

        </form>
        <button className="button-signup" onClick={onSubmit}>SIGN UP</button>
      </div>
      </div>
    </div>
    </div>
    </div>
  );
}

export default SignupForm;