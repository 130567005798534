import React from 'react';
import './Footer.css'

function Footer() {

  return (

    <div className="footer-container">

      <div className="footer-clean">
        <footer>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-4 col-md-3 item">
                <h3>Satyagraha</h3>
                <ul>
                  <li><a href="https://work.satyagraha.dev/our-values/">About</a></li>
                  <li><a href="https://work.satyagraha.dev/our-values/">Values</a></li>
                  <li><a href="https://work.satyagraha.dev/moving-people-up/">MovingPeopleUp</a></li>
                </ul>
              </div>

              <div className="col-sm-4 col-md-3 item">
                <h3>Links</h3>
                <ul>
                  <li><a href="#">Blog</a></li>
                  <li><a href="#">Privacy Policy</a></li>
                </ul>
              </div>
              <div className="col-lg-3 item social"><a href="#"><i className="icon ion-social-twitter"></i></a><a href="#"><i className="icon ion-social-instagram"></i></a>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/js/bootstrap.bundle.min.js"></script>
    </div>
  );
}

export default Footer;