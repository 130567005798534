import React from 'react';
import MainNavigation from './components/MainNavigation';
import Footer from './components/Footer';


export default function Website() {

    
  function updateIframeContent(apiUrl) {
      // Fetch data from the API using a GET request
      fetch(apiUrl)
          .then(response => response.text())
          .then(data => {
              // Access the iframe element
              const iframe = document.getElementById('myIframe');
              
              // Update the src attribute of the iframe with the fetched data
              iframe.src = "data:text/html;charset=utf-8," + escape(data);
          })
          .catch(error => console.error('Error fetching data:', error));
  }
  updateIframeContent('https://work.satyagraha.dev/our-values');

  return (
    <div>
      <div>
        <MainNavigation />
        <iframe id="myIframe" width="100%" height="1300px"></iframe>
      </div>


      <Footer />
    </div>
  );
}





// export default function LandingPage() {
//   console.log("him")
//   return (

//   );
// }
